<script setup>
import {useForm, usePage} from '@inertiajs/vue3'
import InputError from '@Components/InputError.vue'
import InputField from '@Components/InputField.vue'
import Button from "@Components/Button.vue"
import {notify, socialite, useCaptcha} from "@Res/js/helpers"
import {SocialitePlatform} from "@Res/js/enums"
import {onMounted} from "vue";
import AnchorLink from "@Components/AnchorLink.vue";
import DialogModal from "@Components/DialogModal.vue";

const emit = defineEmits(["close"]);
const props = defineProps({
    status: String,
    show: Boolean,
})

const form = useForm({
    email: '',
    password: '',
    _captcha: '',
})

const {execute} = useCaptcha()

const submit = async () => {
    form._captcha = await execute('login')

    form.transform(data => ({
        ...data,
    })).post(route('storefront.login', usePage().props.store.slug), {
        onSuccess: () => window.location.reload(),
        onFinish: () => form.reset('password')
    })
}

onMounted(() => {
    if (typeof usePage().props.errors !== 'undefined' && Object.values(usePage().props.errors).length > 0) {
        notify({text: Object.values(usePage().props.errors)[0], type: 'error'})
    }
})

const close = () => {
    form.email = "";
    form.password = "";

    emit("close");
};

</script>

<template>
    <DialogModal :closeable="false" :show="show" max-width="lg" titleClass="text-center" @close="close">
        <template #title>
            Sign in with
        </template>

        <template #content>
            <div>

                <div class="mt-6 flex items-center justify-between space-x-3">
                    <Button class="space-x-2 !shadow-lg" kind="google-alt"
                            @click.prevent="socialite(SocialitePlatform.GOOGLE)">
                        <svg class="size-5">
                            <use href="#icon-google-logo"/>
                        </svg>
                        <span>Google</span>
                    </Button>
                </div>

                <div class="relative py-4">
                    <div class="absolute inset-0 flex items-center">
                        <div class="w-full border-b border-gray-300"></div>
                    </div>
                    <div class="relative flex justify-center">
                        <span class="bg-white px-4 text-sm text-gray-500">OR</span>
                    </div>
                </div>

                <div class="flex flex-col space-y-4">
                    <div>
                        <InputField v-model="form.email" autocomplete="no-email" autofocus class="mt-1 w-full"
                                    placeholder="Email address" required rounded type="email"/>
                        <InputError :message="form.errors.email" class="mt-2"/>
                    </div>

                    <div>
                        <InputField v-model="form.password" autocomplete="current-password" class="mt-1 w-full"
                                    placeholder="Password" required rounded type="password"/>
                        <InputError :message="form.errors.password" class="mt-2"/>
                    </div>

                    <div>
                        <InputError :message="form.errors._captcha" class="mt-2"/>
                    </div>

                    <div class="flex items-center justify-between">
                        <span>Or <AnchorLink v-if="$page.props.canRegister" :href="route('register')" size="sm">create an
                                account</AnchorLink>
                        </span>

                        <AnchorLink v-if="$page.props.canResetPassword" :href="route('password.request')" size="sm">
                            Forgot your password?
                        </AnchorLink>
                    </div>
                </div>
            </div>
        </template>

        <template #footer>
            <div class="flex justify-between items-center space-x-4 w-full">
                <Button fit kind="white" @click="close" v-text="'Cancel'"/>

                <Button :processing="form.processing" @click="submit">
                    Log in
                </Button>
            </div>
        </template>
    </DialogModal>
</template>
